import { useContext, useState } from "react";
import globalStates from "../../../../utils/global";
import "./DemoNavbar.css";
import { IconBurger, IconClose, IconLogoDemoTravel } from "../../../../assets";
import { useNavigate } from "react-router-dom";

const DemoNavbar = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    const screensOffset = globalContext && globalContext.screensOffset;
    const isOffsetScreenDemoTravelHome1 = screensOffset && screensOffset.isOffsetScreenDemoTravelHome1;

    const navigate = useNavigate();
    const handleNavbar=(path:string)=>{
        const route = path || "home";
        navigate(route);
    };


    const [isCollapsed, setIsCollapsed] = useState(false);
    const handleCollapsed=()=>{
        setIsCollapsed(!isCollapsed);
    }
    return(
        <>
        <nav className={`${windowWidthClass}-travel-demo-navbar
            ${isOffsetScreenDemoTravelHome1?"offset":""}
        `}>
            <div className="anchor-group bar-1">
                <div className="figure-wrapper">
                    <img alt="logo demo travel bikinin site" src={IconLogoDemoTravel} />
                    <h1>Ravelin</h1>
                </div>
            </div>
            <div className="anchor-group bar-2">
                {
                    windowWidthClass==="w-mob"?
                    <img alt="burger icon demo travel bikinin site" src={IconBurger}
                    onClick={handleCollapsed}
                    />:
                    <>
                        <p onClick={()=>handleNavbar(`/demo/travel/home`)}>home</p>
                        <p onClick={()=>handleNavbar(`/demo/ecommerce/travel/paket`)}>paket</p>
                        <p onClick={()=>handleNavbar(`/demo/ecommerce/travel/about`)}>about us</p>
                    </>
                }
                    
            </div>
        </nav>  
           <div className={`w-mob-demo-collapsed-bar
            ${isCollapsed? "collapsed":""}
            `}>
                <img alt="close icon bikinin site" src={IconClose} 
                onClick={handleCollapsed} />
                <p onClick={()=>handleNavbar(`/demo/travel/home`)}>home</p>
                <p onClick={()=>handleNavbar(`/demo/travel/paket`)}>paket</p>
                <p onClick={()=>handleNavbar(`/demo/travel/about`)}>about us</p>
           </div>
        </>
    )
};

export default DemoNavbar;