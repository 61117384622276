
import './App.css';
import globalStates from './utils/global'
import { useWindowSize } from '@uidotdev/usehooks';
import { useEffect,  useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ScrollToTop } from './components';
import {  About, Demo, Harga, Home, RedirectPage, RequirementFormPage } from './pages';
import { DemoTravelHome, DemoTravelPaket } from './pages/DemoTravel/pages';


function App() {
  const globalContext = globalStates && globalStates.globalContext as any;

  const [windowWidthClass, setWindowWidthClass] = useState("w");
  const windowSize = useWindowSize() as {width: any, height: any};
  const windowWidth = windowSize && windowSize.width;
  const windowHeight = windowSize && windowSize.height;

  const [screensOffset, setScreensOffset] = useState({} as any);
  const [appData, setAppData]=useState({
    home: {},
    harga: {},
    about: {},
    demo: {}
  })

  useEffect(
    ()=>{
      if(+windowWidth < 650){
      setWindowWidthClass("w-mob");
    }

    else if (+windowWidth >= 650 && +windowWidth < 1025) {
      setWindowWidthClass("w-tab");
    }
    
    else {
      setWindowWidthClass("w-pc");
    }
    }, [windowSize, windowWidth, windowHeight, screensOffset]
  )
  return (
  <>
    <globalContext.Provider value={{
        windowSize, 
        windowWidthClass, 
        windowHeight, 
        screensOffset, 
        setScreensOffset,
        appData, 
        setAppData
      }}>
        <BrowserRouter>
          <ScrollToTop/>
          <Routes>
            <Route path="/" element={<Home/>} />
            
            <Route path="/harga" element={<Harga/>} />
            <Route path="/about" element={<About/>} />
            <Route path="/demo" element={<Demo/>} />
            
            <Route path="/demo/travel/:demoPage" element={ <DemoTravelHome />} />
            <Route path="/demo/ecommerce/travel/paket" element={ <DemoTravelPaket />} />
            <Route path="/form" element={<RequirementFormPage/>} />
            <Route path="/redirect-page" element={<RedirectPage/>}/>
          </Routes>
        </BrowserRouter>
    </globalContext.Provider>
  </>
  );
}

export default App;
