import { useContext, useEffect, useRef, useState } from "react";
import globalStates from "../../utils/global";
import "./HomeScreen1.css";
import { HomeMacbookImg2 } from "../../assets";
import { useNavigate } from "react-router-dom";

const HomeScreen1 = () =>{
    const navigate = useNavigate();
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    // const setScreensOffset = globalContext && globalContext.setScreensOffset;

    const [isTimeout, setIsTimeout] = useState(false);

    const screenHome1Ref = useRef(null) as any;
    // console.log(">>>", {setScreensOffset})
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenHome1 = screenHome1Ref.current.getBoundingClientRect();
            const top = screenHome1.top;
            const bottom = screenHome1.bottom;
            const offset = globalContext && globalContext.windowHeight;
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetScreenHome1: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetScreenHome1: true,
                }});
            };
        
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    });

 
    useEffect(()=>{
     
        let seconds = 100;
        if (windowWidthClass==="w-pc"){
            seconds = 100;
        }
        setTimeout(()=>{
            setIsTimeout(true)
        }, seconds)
       
        
        
    }, [setIsTimeout, windowWidthClass]);

    const handleOrderForm = ()=>{
        navigate("/form")
    }

    return(
        <div ref={screenHome1Ref} className={`${windowWidthClass}-home-screen1
            ${isTimeout? "timeout":""}
        `}>
            <div className="grid-item tagline">
                <h1>
                    Bikinin website untuk bisnis kamu
                </h1>
                <h2>
                    Aktifkan solusi digital sekarang
                </h2>
                
            </div>
             <div className="grid-item canva">
                <img 
                    alt={"pc view website in macbook-like device"}
                    src={HomeMacbookImg2}
                />
                {
                    windowWidthClass==="w-mob"?
                    <h3 onClick={handleOrderForm}>ORDER</h3>:null
                }
            </div>
        </div>
    )
};

export default HomeScreen1;