
import { useContext } from "react";
import globalStates from "../../utils/global";
import "./HomeScreen2.css";

const HomeScreen2 = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    return(
        <div className={`${windowWidthClass}-home-screen2`}>
            <div className="text-wrapper">
                <h1>Sesuaikan kebutuhanmu</h1>
            </div>
        </div>
    )
};

export default HomeScreen2