import "./FloatingIcon.css";
import globalStates from '../../utils/global';
import { useContext } from "react";
import { IconWaGreen } from "../../assets";

const FloatingIcons = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    const contactWa = "6287715376976";

    const openLinkHandler = (link:any) =>{
        // console.log("link", link)
        window.open(link, '_blank');
    }

    const openWa = (wa:string)=>{
        const waNumber = wa || contactWa;
        const waMessage = `Halo,%20saya%20mau%20request%20%20dibikinin%20website.%20Mohon%20bantuan%20informasi%20prosedur%20dan%20harganya.%20Terima%20kasih.%20`
        openLinkHandler(`https://wa.me/${waNumber}?text=${waMessage}`)
    }

    return (
        <div className={`${windowWidthClass}-floating-icon`}>
            <img src={IconWaGreen} alt="green whatsapp icon"
                onClick={()=>openWa(contactWa)}
            />
        </div>
    )
}

export default FloatingIcons;