import { useContext } from "react";
import globalStates from "../../utils/global";
import "./RedirectPage.css"
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";

const RedirectPage = () =>{
    const navigate = useNavigate();
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const requirementFormState = useSelector((state:any)=> state.requirementForm);
    const requirementFormStatePayload = requirementFormState && requirementFormState.payload;
    const requirementFormStateLoading = requirementFormState && requirementFormState.loading;
    const requirementFormStateError = requirementFormState && requirementFormState.error;

    const payloadData = (requirementFormStatePayload && requirementFormStatePayload.data) || {};
    const requestId = (payloadData && payloadData.requestId) || "#";
    const openLinkHandler = (link:any) =>{
        window.open(link, '_blank');
    }
    const handleOrderAdmin = (paketString:string) =>{
        const requestid = paketString || "ini";
        const waMessage = `https://wa.me/6287715376976?text=Halo,%20saya%20mau%20request%20%20dibikinin%20website%20sesuai%20kode%20request%20${requestid}.%20Tolong%20arahkan%20sesuai%20prosedur.%20Terima%20kasih.%20`
        openLinkHandler(waMessage);
    } 
    const handleButton = ()=>{
        navigate("/");
    }
    return(
        <div className={`${windowWidthClass}-redirect-page`}>
            <h1>REQUEST TERKIRIM!</h1>
            <div className="page-desc">
                <h3>Simpan kode request dan hubungi admin untuk lanjut pembuatan website.</h3>
            </div>
            <div className="kode-request">
                <p>kode request:</p>
                {
                    requirementFormStateLoading ? "#" :
                    requirementFormStateError ? "#" :
                    <h2>{requestId}</h2>}
            </div>
            <h3 className="back-button admin" onClick={()=>handleOrderAdmin(requestId)}>kirim kode request ke admin</h3>
            <h3 className="back-button" onClick={handleButton}>balik ke menu utama</h3>
        </div>
    )
};

export default RedirectPage;