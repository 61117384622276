import { AppFooter, AppNavbar } from "../../components";
import { AboutScreen1, AboutScreen2 } from "../../containers";
import "./About.css";

const About = ()=>{

    return (
        <div className="app-page about">
            <AppNavbar/>
            <AboutScreen1/>
            <AboutScreen2/>
            <AppFooter/>
        </div>
    )
};

export default About;