import { useContext } from "react";
import globalStates from "../../utils/global";
import "./AppFooter.css";
import { IconFooterInsta, IconFooterMail, IconFooterWa } from "../../assets";
import { FloatinIcons } from "..";

const AppFooter = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const openLinkHandler = (link:any) =>{
        window.open(link, '_blank');
    }
    const redirectToInstagram = (profile:string) => {
        // window.location.href = `https://www.instagram.com/${profile}`;
        openLinkHandler(`https://www.instagram.com/${profile}`);
    };
    const handleButtonLayanan = () =>{
        const waMessage = `https://wa.me/6287715376976?text=Halo,%20saya%20mau%20request%20%20dibikinin%20website.%20Mohon%20bantuan%20informasinya.%20Terima%20kasih.%20`
        openLinkHandler(waMessage);
    } 

    const handleEmail = (destination:string) =>{
        const email = destination || 'team@bikinin.site';
        const subject = 'Request bikin website ';
        const body = 'Halo, saya mau bikin website. Mohon arahanya';

        // Create the mailto link
        const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;

        // Create a temporary anchor element to trigger the mail client
        const tempLink = document.createElement('a');
        tempLink.href = mailtoLink;
        tempLink.click();
    }

    const contactInsta = "bikininsite";
    const contactWa = "+6287715376976";
    const contactMail = "team@bikinin.site"
    
    return(
        <>
        <div className={`${windowWidthClass}-app-footer`}>
            <div className="text-wrapper">
                <h2>bikinin</h2><h3>.site</h3>
            </div>
            <div className="contacts">
                <div className="footer-wrap">
                    <img alt="icon whatsapp bikinin site" src={IconFooterWa} />
                     <p onClick={handleButtonLayanan}>{contactWa}</p>
                </div>
                <div className="footer-wrap">
                    <img alt="icon email bikinin site" src={IconFooterMail} />
                     <p onClick={()=>handleEmail(contactMail)}>{contactMail}</p>
                </div>
                 <div className="footer-wrap">
                    <img alt="icon instagram bikinin site" src={IconFooterInsta}/>
                     <p onClick={()=>redirectToInstagram(contactInsta)}>@{contactInsta}</p>
                </div>
            </div>
            
        </div>
        <FloatinIcons/>
        </>
    )
};

export default AppFooter;