import { useContext } from "react";
import globalStates from "../../utils/global";
import "./AboutScreen2.css";

const AboutScreen2 = ()=>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    const dataParagraph = [
        {
            title: "Tentang Kami",
            desc: "Bikinin.site adalah tim pengembang fullstack freelance independen yang berdedikasi dan berpengalaman. Meskipun kami bekerja secara independen, kami tetap menjunjung tinggi profesionalisme dan kompetensi dalam setiap proyek yang kami tangani."
        },
        {
            title: "Profesionalisme",
            desc: "Sebagai pengembang fullstack, kami memiliki keahlian yang luas dari front-end hingga back-end, memastikan setiap bagian dari proyek Anda terkelola dengan baik. Kami memahami pentingnya kualitas dan detail dalam pengembangan perangkat lunak, sehingga kami selalu berusaha memberikan yang terbaik dalam setiap langkah kerja kami."
        },
        {
            title: "Kompetensi yang Teruji",
            desc: "Kami terus mengasah kemampuan dan mengikuti perkembangan teknologi terbaru untuk memastikan solusi yang kami berikan selalu up-to-date dan efektif, sehingga keahlian kami tetap siap diandalkan."
        },
        {
            title: "Kualitas yang Dijamin",
            desc: "Kepuasan Anda adalah prioritas utama kami. Dengan pendekatan yang teliti dan komunikasi yang terbuka, kami berkomitmen untuk memberikan hasil yang sesuai harapan Anda. Dari perencanaan hingga implementasi, kami memastikan setiap proyek selesai dengan hasil maksimal."
        },

    ]
    
    

    return(
        <div className={`${windowWidthClass}-about-screen2`}>
            {
                dataParagraph.map((paragraph:any, index:any)=>{
                    const {title, desc} = paragraph;
                    return (
                        <div className="text-wrapper">
                            <h3>{title}</h3>
                            <p>{desc}</p>
                        </div>
                    )
                })
            }
        </div>
    )
};
export default AboutScreen2;