import { useContext, useEffect, useState } from "react";
import globalStates from "../../utils/global";
import "./HargaScreen2Webapp.css";

const HargaScreen2Webapp = () => {

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    const [isTimeout, setIsTimeout]=useState(false);
    useEffect(()=>{
        setTimeout(()=>{
            setIsTimeout(true);
        }, 0)
    })

    const name = "WEB APPLICATION"
    const desc = "Upgrade paket website dengan fitur online payment, user login, online booking, dan user dashboard untuk aktifkan solusi e-commerce lengkap."
    const developmentFee = "IDR 4.290.000,-"
    const serverFee = "IDR 700.000,-"
    const totalFee = "IDR 4.990.000,-"
    
    return (
        <div className={`${windowWidthClass}-harga-screen2-webapp
            ${isTimeout ? "timeout":""}
        `}>
           <div className="wrapper">
                <div className="screen-title">
                    <h2>Paket Website + Web Application</h2>
                </div>
                <div className="screen-content">
                    <div className="grid-item">
                        <h3>{name}</h3>
                        <p>{desc}</p>
                        <div className="price-box">
                            <p>Development Fee </p>
                            <p>{developmentFee}</p>
                        </div>
                        <div className="price-box">
                            <p>Server Request per tahun </p>
                            <p>{serverFee}</p>
                        </div>
                        <div className="price-box total">
                            <p>TOTAL </p>
                            <p className="">{totalFee}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>  
    )
};

export default HargaScreen2Webapp;