import { useContext, useEffect, useRef, useState } from "react";
import globalStates from "../../../../utils/global";
import "./DemoTravelHomeScreen1.css"
import { IconCalendarDemoTravel, IconPinDemoTravel, ImgBannerDemoTravel4, ImgBannerDemoTravel5 } from "../../../../assets";
import { useParams } from 'react-router-dom';

const options = [
    { value: 'option1', label: 'Bali' },
    { value: 'option2', label: 'Paris' },
    { value: 'option3', label: 'Jepang' }
];
const optionsMonth = [
    { value: 'option1', label: 'May' },
    { value: 'option2', label: 'June' },
    { value: 'option3', label: 'July' },
    { value: 'option4', label: 'October' },
    { value: 'option5', label: 'November' },
    { value: 'option6', label: 'December' }
];

const DemoTravelHomeScreen1 =()=>{

    const { demoPage } = useParams();

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const [selectedOption, setSelectedOption] = useState(options[0].value);
    const [selectedMonth, setSelectedMonth] = useState(optionsMonth[0].value);
    const handleSelectChange = (event:any) => {
        setSelectedOption(event.target.value);
    };
    const handleSelectMonthChange = (event:any) => {
        setSelectedMonth(event.target.value);
    };

    const screenDemoTravelHomeRef = useRef(null) as any;
    // console.log(">>>", {setScreensOffset})
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenHome1 = screenDemoTravelHomeRef.current.getBoundingClientRect();
            const top = screenHome1.top;
            const bottom = screenHome1.bottom;
            const offset = globalContext && globalContext.windowHeight;
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetScreenDemoTravelHome1: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetScreenDemoTravelHome1: true,
                }});
            };
        
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    });

    let tagline = demoPage || "";
    let bannerImg = ImgBannerDemoTravel4;
    if(tagline?.toLocaleLowerCase()==="home"){
        tagline = "Nikmati liburan tanpa ribet";
    }
    if(tagline?.toLocaleLowerCase()==="paket"){
        tagline = "Paket Travel & Tour";
        bannerImg = ImgBannerDemoTravel5;
    }
    if(tagline?.toLocaleLowerCase()==="about"){
        tagline = "Tentang Kami"
    }
    
    
  
    return(
        <div ref={screenDemoTravelHomeRef}  className={`${windowWidthClass}-travel-demo-home-screen1`} >
            <div className="wrapper">

               

                <div className="sections left">
                    <div className="input-wrapper">
                        <select value={selectedOption} onChange={handleSelectChange} className="custom-select">
                            {options.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <img alt="icon pin map bikinin site" src={IconPinDemoTravel} />
                    </div>
                    <div className="input-wrapper">
                        <select value={selectedMonth} onChange={handleSelectMonthChange} className="custom-select">
                            {optionsMonth.map((option, index) => (
                                <option key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                        <img alt="icon calendar bikinin site" src={IconCalendarDemoTravel}/>
                    </div>
                    <h3>SEARCH TRIP</h3>
                </div>
                {
                    windowWidthClass==="w-pc"?
                    <div className="sections right">
                    <div className="wrapper">
                        <div className="image">
                            <img alt="travellers bikinin site" src={bannerImg}/>
                        </div>  
                        <div className="shield">

                        </div>
                        <div className="content">
                            <h2>{tagline}</h2>
                        </div>
                    </div>
                </div>: null

                }

                 


            </div>
        </div>
    )
};

export default DemoTravelHomeScreen1;