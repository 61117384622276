import { useContext } from "react";
import globalStates from "../../../../utils/global";
import "./DemoFooter.css";
import { IconLogoDemoTravel } from "../../../../assets";

const DemoFooter = () =>{
    
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    return(
        <div className={`${windowWidthClass}-demo-footer`}>
            <div  className="logo">
                <img alt="logo demo travel bikinin site"  src={IconLogoDemoTravel}/>
                <h2>Ravelin</h2>
            </div>
            <div className="footer-group">
                <h3>Promo</h3>
                <p>paket tour 1</p>
                <p>paket tour 2</p>
                <p>paket tour 3</p>
            </div>
             <div className="footer-group">
                <h3>Contacts</h3>
                <p>081235367889</p>
                <p>ravelin@email.com</p>
                <p>@bikinintour</p>
            </div>
            <div className="footer-group">
                <h3>Contacts</h3>
                <p>081235367889</p>
                <p>ravelin@email.com</p>
                <p>@bikinintour</p>
            </div>
        </div>
    )
};

export default DemoFooter;