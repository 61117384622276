import { useContext, useEffect, useRef } from "react";
import globalStates from "../../../../utils/global";
import "./DemoTravelPaketScreen1.css";

const DemoTravelPaketScreen1 = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const screenDemoTravelPaketRef = useRef(null) as any;
    // console.log(">>>", {setScreensOffset})
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenHome1 = screenDemoTravelPaketRef.current.getBoundingClientRect();
            const top = screenHome1.top;
            const bottom = screenHome1.bottom;
            const offset = globalContext && globalContext.windowHeight;
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetScreenDemoTravelHome1: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetScreenDemoTravelHome1: true,
                }});
            };
        
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    });
    
    return(
        <div ref={screenDemoTravelPaketRef}  className={`${windowWidthClass}-demo-travel-paket-screen-1`}>
            <div className="image">

            </div>
            <div className="shield">

            </div>
            <div className="content">

            </div>
        </div>      
    )
};
export default DemoTravelPaketScreen1;