import { AppNavbar } from "../../components";
import "./Demo.css";

const Demo = ()=>{

    return (
        <div className="app-page demo">
            <AppNavbar/>
        </div>
    )
};

export default Demo;