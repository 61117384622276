import "./AppNavbar.css"
import { useContext, useState } from "react";
import globalStates from "../../utils/global";
import { useNavigate } from "react-router-dom";
import { IconBurger, IconClose } from "../../assets";



const AppNavbar = () =>{
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    const screensOffset = globalContext && globalContext.screensOffset;
    const isOffsetScreenHome1 = screensOffset && screensOffset.isOffsetScreenHome1;


    const [isCollapsed, setIsCollapsed] = useState(false);
    // console.log(">>>", {isOffsetScreenHome1})
    const navigate = useNavigate();

    const handleNavigate=(path:string)=>{
        const pathname = path || "";
        navigate(`/${pathname}`)
    };
    const handleCollapsed=()=>{
        setIsCollapsed(!isCollapsed);
    }

    
    return(
        <>
        <nav className={`
        ${windowWidthClass}-app-navbar
        ${isOffsetScreenHome1 ? "offset-home-screen1" : ""}
        `}>
            <div className="anchor-group bar-1">
                <div className="text-wrapper" onClick={()=>handleNavigate("")}>
                    <h1 >
                    bikinin
                </h1>
                <h2>
                    .site
                </h2>
                </div>
            </div>
            <div className="anchor-group bar-2">
                {
                    windowWidthClass==="w-mob"?
                    <>
                       
                    </> : 
                    <>
                        <p onClick={()=>handleNavigate("")}>Home</p>
                        <p onClick={()=>handleNavigate("harga")}>Harga</p>
                        <p onClick={()=>handleNavigate("about")}>About Us</p>
                    </>
                }
                
                {/* <p onClick={()=>handleNavigate("demo")}>Demo</p> */}
            </div>
            <div className="anchor-group bar-3">
                {
                    windowWidthClass==="w-mob"?
                    <>
                        <img alt="burger icon bikinin site" src={IconBurger}
                            onClick={handleCollapsed}
                        />
                    </>:
                    <>
                        <h3 onClick={()=>handleNavigate("form")}>ORDER</h3>
                    </>
                }
            </div>
        </nav>
        
           
            <div className={`w-mob-collapsed-bar
                ${isCollapsed?"collapsed":""}
                `}>
                <div className="close">
                    <img alt="bikinin site close icon" src={IconClose}
                        onClick={handleCollapsed}
                    />
                </div>
                <div className="collapsed-menu">
                    <p onClick={()=>handleNavigate("")}>Home</p>
                    <p onClick={()=>handleNavigate("harga")}>Harga</p>
                    <p onClick={()=>handleNavigate("about")}>About Us</p>
                </div>
            </div> 
        
        </>
    )
};

export default AppNavbar;