import { configureStore ,} from "@reduxjs/toolkit";
import {thunk} from "redux-thunk";
import { requirementFormReducer } from "../reducers";

const store = configureStore({
  reducer: {
    requirementForm: requirementFormReducer
  },
  middleware: ()=>[thunk ]as any
});

export default store;
