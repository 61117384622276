import { useContext, useEffect, useState } from "react";
import globalStates from "../../utils/global";
import "./HargaScreen2Online.css";

const HargaScreen2Online = ()=>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    const [isTimeout, setIsTimeout]=useState(false);
    useEffect(()=>{
        setTimeout(()=>{
            setIsTimeout(true);
        }, 0)
    })

    const name = "ONLINE PAYMENT"
    const desc = "Upgrade paket website dengan tambahan fitur online payment untuk aktifkan solusi e-commerce sederhana."
    const developmentFee = "IDR 2.000.000,-"
    const serverFee = "IDR 518.000,-"
    const totalFee = "IDR 2.518.000,-"
    return(
        <div className={`${windowWidthClass}-harga-screen2-online
            ${isTimeout ? "timeout":""}
        `}>
            <div className="wrapper">
                <div className="screen-title">
                    <h2>Paket Website + Online Payment</h2>
                </div>
                <div className="screen-content">
                    <div className="grid-item">
                        <h3>{name}</h3>
                        <p>{desc}</p>
                        <div className="price-box">
                            <p>Development Fee </p>
                            <p>{developmentFee}</p>
                        </div>
                        <div className="price-box">
                            <p>Server Fee per tahun </p>
                            <p>{serverFee}</p>
                        </div>
                        <div className="price-box total">
                            <p>TOTAL </p>
                            <p className="">{totalFee}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default HargaScreen2Online;