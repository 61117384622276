import { useContext } from "react";
import globalStates from "../../utils/global";
import "./LoadingBar.css";

const LoadingBar = ()=>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    return(
        <div className={`${windowWidthClass}-loading-bar`}>
            <div className="circle">
                <p>sending</p>
            </div>
        </div>
    )
};
export default LoadingBar;