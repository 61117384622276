import { useContext, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import globalStates from "../../utils/global";
import "./RequirementForm.css";
import { requirementFormAction } from "../../actions";
import { UnknownAction } from "@reduxjs/toolkit";
import { ErrorSnackBar, LoadingBar } from "../../components";
import { useNavigate } from "react-router-dom";

const RequirementForm = () =>{
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass
    const screensOffset = globalContext && globalContext.screensOffset;
    const isOffsetScreen1_25 = screensOffset && screensOffset.isOffsetScreen1_25;
    const isOffsetScreen1_50 = screensOffset && screensOffset.isOffsetScreen1_50;
    const isOffsetScreen1_75 = screensOffset && screensOffset.isOffsetScreen1_75;
    
    const [menuCount, setMenuCount] = useState(5);

    const [formData, setFormData] = useState({
        clientName: "",
        clientContact: "",
        websiteGoal: "",
        websiteBrand: "",
        websiteTagline: "",
        websiteDomain: "",
        websiteDetails: {
            menuCount: "",
            menuDetails: []
        },
        organization: ["bikinin-client"],
        permission: ["bikinin-client"]
    });

    const handleChange = (e:any) => {
        const {id, name, value } = e.target;
        
        let targetValue = value
        if(name==="websiteDetails"){
            setMenuCount(value);
            targetValue = {
                menuCount: value,
                menuDetails: []
            }
        }

        if(name && name.includes("menuDetails")){
            // console.log('true');
            setFormMenuDetails((prevDetails:any[])=>{
                let tempObj = {} as any;
                const targetObj:any = prevDetails.filter((x:any)=> x.hasOwnProperty(name))[0];
                const targetOthers = prevDetails.filter((x:any)=> !x.hasOwnProperty(name));
                if(id && id.includes("menunama")){
                    tempObj[name] = {
                        ...targetObj[name], menuName: value
                    }
                };
                if(id && id.includes("menudesc")){
                    tempObj[name] = {
                        ...targetObj[name], menuDesc:  value
                    }
                };
                setFormData((prev:any)=>{
                    return {...prev, websiteDetails: {
                        menuCount, menuDetails: [...targetOthers,tempObj ]
                    }}
                })
                // console.log({berubah: [...targetOthers,tempObj ]})
                return [...targetOthers,tempObj ]
            })
        } else {
             setFormData({
            ...formData,
            [name]: targetValue
            });
        }
    };

    const handleSubmit = async (e:any) => {
        try{
            e.preventDefault();
            
            dispatch(requirementFormAction({
                data: formData,
                method: "POST",
                cb: ()=>navigate("/redirect-page")
            }) as unknown as UnknownAction);
        } catch(error:any){
            // const errorResponse = {error};
            // console.log(errorResponse);
        }
    };

    // const [onTimeOutAnimation, setOnTimeOutAnimation] = useState(false);
    const screen1Ref = useRef(null) as any;

    useEffect(()=>{
        const handleScroll = () =>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screen1 = screen1Ref.current.getBoundingClientRect();
            const top = screen1.top;
            const bottom = screen1.bottom;
            const offset = globalContext && globalContext.windowHeight;
            // console.log("scrolling")    
            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetScreen1: false, bottom, offset, top,
                isOffset50Screen1: false
            }});
            if(top < (-1*0.1*offset) && bottom < 0.1*offset ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetScreen1: true,
                    isOffsetScreen1_25: false,
                    isOffsetScreen1_50: false,
                    isOffsetScreen1_75: false
                }});
            };
            if(top < (-1*0.1*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top, 
                    isOffsetScreen1: false,
                    isOffsetScreen1_25: true,
                    isOffsetScreen1_50: false,
                    isOffsetScreen1_75: false
                }});
            };
            if(top < (-1*0.30*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetScreen1: false,
                    isOffsetScreen1_25: false,
                    isOffsetScreen1_50: true,
                    isOffsetScreen1_75: false
                }});
            };
            if(top < (-1*0.98*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetScreen1: false,
                    isOffsetScreen1_25: false,
                    isOffsetScreen1_50: false,
                    isOffsetScreen1_75: true
                }});
            };
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    },[globalContext])

    const [formMenuDetails, setFormMenuDetails] = useState([] as any);
    useEffect(
        ()=>{
            
            let menuDetailsList = [] as any;
            for (let i = 0; i < menuCount; i++) {
                const index = +i + 1;
                let tempObj = {} as any;
                tempObj["menuDetails"+index] = {
                    menuName: "",
                    menuDesc: ""
                };
                menuDetailsList.push(tempObj)
            }
            // console.log("menu count", menuCount, menuDetailsList)
            setFormMenuDetails(menuDetailsList)
        }, [menuCount]
    )
    // console.log("form-menu-details", {formMenuDetails})
    
    const requirementFormState = useSelector((state:any)=> state.requirementForm);
    // const requirementFormStatePayload = requirementFormState && requirementFormState.payload;
    const requirementFormStateLoading = requirementFormState && requirementFormState.loading;
    const requirementFormStateError = requirementFormState && requirementFormState.error;
    const requirementFormStateMessage = requirementFormState && requirementFormState.message;
    useEffect(()=>{
    //    console.log({requirementFormState});
    }, [dispatch, requirementFormState])
    return (
        <>
        {
            requirementFormStateLoading ? <LoadingBar/> : <></> 
        }
        {
             requirementFormStateError ? <ErrorSnackBar message={requirementFormStateMessage}/> : <></>
        }
        <div ref={screen1Ref}  className={`${windowWidthClass}-cont-requirement-form 
        ${isOffsetScreen1_25?"offset-25":""}
        ${isOffsetScreen1_50?"offset-50":""}
        ${isOffsetScreen1_75?"offset-75":""}
        `}>{
            
          
           
            <form onSubmit={handleSubmit}>
                <div className="title">
                    <h1>bikinin</h1>
                    <h3>.site</h3>
                </div>
                <div className="desc">
                    <h2>Langkah awal pembuatan website ideal kamu. Isi <b>Form Kebutuhan</b>!</h2>
                </div>
                <div>
                    <label htmlFor="clientNAme">1. Nama</label>
                    <input
                    type="text"
                    id="clientName"
                    name="clientName"
                    value={formData.clientName}
                    onChange={handleChange}
                    placeholder="Nama client atau PIC website"
                    />
                </div>
                <div>
                    <label htmlFor="contact">2. Kontak yang bisa dihubungi (nomor whatsapp)</label>
                    <input
                    type="text"
                    id="clientContact"
                    name="clientContact"
                    value={formData.clientContact}
                    onChange={handleChange}
                    placeholder="Nomor whatsapp atau email"
                    />
                </div>
                <div>
                    <label htmlFor="websiteGoal">3. Tujuan pembuatan website</label>
                    <input
                    type="text"
                    id="websiteGoal"
                    name="websiteGoal"
                    value={formData.websiteGoal}
                    onChange={handleChange}
                    placeholder="Contoh: Untuk bisnis travel"
                    />
                </div>
                <div>
                    <label htmlFor="websiteBrand">4. Nama atau merek yang ingin ditampilkan di website</label>
                    <input
                    type="text"
                    id="websiteBrand"
                    name="websiteBrand"
                    value={formData.websiteBrand}
                    onChange={handleChange}
                    placeholder="Contoh: Ravelin Tour"
                    />
                </div>
                <div>
                    <label htmlFor="websiteTagline">5. Tuliskan tagline yang ingin ditampilkan di website</label>
                    <input
                    type="text"
                    id="websiteTagline"
                    name="websiteTagline"
                    value={formData.websiteTagline}
                    onChange={handleChange}
                    placeholder="Contoh: Travelling murah tanpa ribet"
                    />
                </div>
                <div>
                    <label htmlFor="websiteDomain">6. Website Domain. Tuliskan opsi nama website domain yang diinginkan jika belum ada?</label>
                    <input
                    type="text"
                    id="websiteDomain"
                    name="websiteDomain"
                    value={formData.websiteDomain}
                    onChange={handleChange}
                    placeholder="Contoh: travelintour.com, travelin.com"
                    />
                </div>
                <div>
                    <label htmlFor="websiteDetails">7. Ada berapa menu halaman website yang dibutuhkan?</label>
                    <input
                    type="text"
                    id="websiteDetails"
                    name="websiteDetails"
                    value={menuCount}
                    onChange={handleChange}
                    />
                </div>
                {
                    formMenuDetails.map((menuObj:any, index:any)=>{
                        const compKey = +index+1;
                        const targetProp = "menuDetails"+compKey;
                        const targetObj:any = formMenuDetails.filter((x:any)=> x.hasOwnProperty(targetProp))[0];
                        const menuDetail = targetObj[targetProp];
                        const {menuDesc, menuName} = menuDetail;
                        return(
                            <div  key={index} className="form-menu-details">
                                <div className="menu-name">
                                    <label htmlFor={`menuDetails${compKey}`}>Nama Menu #{+index+1}</label>
                                    <input
                                    type="text"
                                    id={`menunama${compKey}`}
                                    name={`menuDetails${compKey}`}
                                    value={menuName}
                                    onChange={handleChange}
                                    placeholder="contoh: Home"
                                    />
                                </div>
                                <div className="menu-desc">
                                    <label htmlFor={`menuDetails${compKey}`}>Deskripsikan content yang diinginkan</label>
                                    <textarea
                                    // type="text"
                                    id={`menudesc${compKey}`}
                                    name={`menuDetails${compKey}`}
                                    value={menuDesc}
                                    onChange={handleChange}
                                    placeholder="contoh: Saya ingin ada banner gambar tentang paket travel, ada summary layanan, dan icon wa kalau di klik bisa terhubung ke wa admin"
                                    />
                                </div>
                            </div>
                        )
                    })
                }
                <button type="submit">BIKININ WEBSITE</button>
            </form>           
        }
         
        </div>
        
          </>
    );
};

export default RequirementForm;