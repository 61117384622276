import { useContext, useEffect, useRef, useState } from "react";
import globalStates from "../../utils/global";
import "./HargaScreen1.css";
import { IconCompanyProfile, IconOnlinePayment, IconWebApplication } from "../../assets";


const HargaScreen1 = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const setAppData = globalContext && globalContext.setAppData;
    
    const screenHarga1Ref = useRef(null) as any;

    const [isTimeout, setIsTimeout]=useState(false);

    const handleSwitchPriceSection = (section:string)=>{
        setAppData((previous:any)=>{ 
            const harga = (previous && previous.harga ) || {};
            const newHarga = {...harga, priceSection: section};
            return {...previous, harga: newHarga} 
        })
    }
    
    useEffect(()=>{
        setTimeout(()=>{
            setIsTimeout(true);
        }, 1000);

        setAppData((previous:any)=>{ 
            const harga = (previous && previous.harga ) || {};
            const newHarga = {...harga, priceSection: "paket-website"};
            return {...previous, harga: newHarga} 
        })
    }, [setAppData])
    
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenHome1 = screenHarga1Ref.current.getBoundingClientRect();
            const top = screenHome1.top;
            const bottom = screenHome1.bottom;
            const offset = globalContext && globalContext.windowHeight;
            

            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetScreenHome1: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetScreenHome1: true,
                }});
            };
        
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    });

    return (
        <div ref={screenHarga1Ref} className={`${windowWidthClass}-harga-screen1
            ${isTimeout?"timeout":""}
        `}>
            <div className="wrapper">
                <div className="menubar">
                        <div className="options bar-1">
                            <div className="figure-wrapper"
                                onClick={()=>handleSwitchPriceSection('paket-website')}
                            >
                                <img alt="paket-website" src={IconCompanyProfile}/>
                                <p>Paket Website</p>
                            </div>
                        </div>
                        <div className="options bar-2">
                            <div className="figure-wrapper"
                                onClick={()=>handleSwitchPriceSection('online-payment')}
                            >
                                <img alt="online-payment" src={IconOnlinePayment}/>
                                <p>Online Payment</p>
                            </div>
                        </div>
                        <div className="options bar-3">
                            <div className="figure-wrapper"
                                onClick={()=>handleSwitchPriceSection('web-app')}
                            >
                                <img alt="web-app" src={IconWebApplication}/>
                                <p>Web Application</p>
                            </div>
                        </div>

                </div>
            </div>
        </div>
    )
};

export default HargaScreen1;