import { createContext } from "react";


const BASE_URL = "https://jstfpnfyte.execute-api.ap-southeast-3.amazonaws.com/dev/requirement-form"
// use-context
const GlobalContext = createContext(null);
const globalStates =  {
    globalContext: GlobalContext,
    BASE_URL
};

export default globalStates as any;
