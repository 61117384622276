import { useContext, useEffect, useState } from "react";
import globalStates from "../../utils/global";
import "./HargaScreen2.css";

const HargaScreen2 = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    // const appData = globalContext && globalContext.appData;

    const [isTimeout, setIsTimeout]=useState(false);
    useEffect(()=>{
        setTimeout(()=>{
            setIsTimeout(true);
        }, 0)
    })

    const dataPaket = [
        {
            name: "BUSINESS",
            desc: "max. 3 menu halaman",
            fee: {
                development:"IDR 590.000,-",
                server:"IDR 518.000,-",
                total: "IDR 1.108.000,-",
                discount: "IDR 590.000,-",
            }
        },
        {
            name: "PREMIUM",
            desc: "max. 8 menu halaman",
            fee: {
                development:"IDR 1.400.000,-",
                server:"IDR 518.000,-",
                total: "IDR 1.918.000,-",
                discount: "IDR 1.400.000,-",
            }
        },
        {
            name: "PREMIUM PLUS",
            desc: "max. 10 menu halaman",
            fee: {
                development:"IDR 1.750.000,-",
                server:"IDR 518.000,-",
                total: "IDR 2.268.000,-",
                discount: "IDR 1.750.000,-",
            }
        },
        {
            name: "SUPERWEB",
            desc: "max. 20 menu halaman",
            fee: {
                development:"IDR 3.500.000,-",
                server:"IDR 518.000,-",
                total: "IDR 4.018.000,-",
                discount: "IDR 3.500.000,-",
            }
        }
    ]
    return(
        <div className={`${windowWidthClass}-harga-screen2
            ${isTimeout ? "timeout":""}
        `}>
            <div className="wrapper">
                <div className="screen-title">
                    <h2>Paket Website</h2>
                </div>
                <div className="screen-content">
                    {
                        dataPaket.map((paket:any, index:any)=>{
                            const {name,desc, fee} = paket;
                            const developmentFee = fee && fee.development;
                            const serverFee = fee && fee.server;
                            const totalFee = fee && fee.total;
                            const discountFee = fee && fee.discount;
                            return(
                                <div key={index} className="grid-item">
                                    <h3>Paket {name}</h3>
                                    <p>{desc}</p>
                                    <div className="price-box">
                                        <p>Development Fee </p>
                                        <p>{developmentFee}</p>
                                    </div>
                                    <div className="price-box">
                                        <p>Maintanance Fee per tahun </p>
                                        <p>{serverFee}</p>
                                    </div>
                                    <div className="price-box total">
                                        <p>TOTAL </p>
                                        <p className="strike">{totalFee}</p>
                                    </div>
                                    <div className="price-box discount">
                                        <p> </p>
                                        <p className="promo">{discountFee}</p>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {/* <h2>Paket Website</h2> */}
                </div>
            </div>
        </div>
    )
};

export default HargaScreen2;