import { useContext } from "react";
import globalStates from "../../utils/global";
import "./HargaScreen3.css";


const HargaScreen3 = ()=>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    return(
        <div className={`${windowWidthClass}-harga-screen3`}>
            <div className="screen-title">
                <h3>Layanan paket sudah termasuk:</h3>
            </div>
            <div className="screen-content">
                <p>Request custom.</p>
                <p>Modern and responsive design, tampilan tetap konsisten dan menarik bila diakses dari semua tipe device (PC, tab, atau mobile).</p>
                <p>Gratis domain .com untuk 1 tahun pertama.</p>
                <p>Gratis biaya maintanance, web-hosting, dan SSL untuk 1 tahun pertama.</p>
                <p>Respon server rata-rata dalam milisecond.</p>
                <p>Free support.</p>
                <p>SEO-friendly web desain (on page).</p>
            </div>
            <div className="screen-title">
                <h3>Add-ons menu halaman</h3>
            </div>
            <div className="screen-content">
                <p>Biaya tambahan IDR 210.000,- per menu halaman.</p>
            </div>
        </div>
    )
};

export default HargaScreen3;