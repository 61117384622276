import { useContext } from "react";
import globalStates from "../../../../utils/global";
import "./DemoTravelHome.css";
import { DemoFooter, DemoNavbar } from "../../component";
import { DemoTravelHomeScreen1, DemoTravelHomeScreen2 } from "../../container";

const DemoTravelHome = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    return(
        <div className={`${windowWidthClass}-travel-demo-home`}>
            <DemoNavbar/>
            <DemoTravelHomeScreen1/>
            <DemoTravelHomeScreen2/>
            <DemoFooter/>
        </div>
    )
};

export default DemoTravelHome;