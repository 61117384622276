import { useContext } from 'react';
import globalStates from '../../../../utils/global';
import './DemoTravelHomeScreen2.css';
import { ImgPromoTravel1, ImgPromoTravel2, ImgPromoTravel3 } from '../../../../assets';

const DemoTravelHomeScreen2 =() =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    const dataPromos = [
        {
            name: "Promo Winter",
            imgSrc: ImgPromoTravel1
        },
        {
            name: "Promo Autumn",
            imgSrc: ImgPromoTravel2
        },
        {
            name: "Promo Group",
            imgSrc: ImgPromoTravel3
        }
    ]

    return(
        <div className={`${windowWidthClass}-travel-demo-home-screen2`}>
            <div className='wrapper'>
                <h2>Latest Promo</h2>
                <div className='cardholder'>
                    {
                        dataPromos.map((promo:any, index:any)=>{
                            const {name, imgSrc} = promo;

                            return(
                                <div className='grid-item'>
                                        <div className='frame'>
                                            <img alt={`${name} bikinin site`} src={imgSrc} />
                                        </div>
                                       
                                        <h3>{name}</h3>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
        </div>
    )
};

export default DemoTravelHomeScreen2;