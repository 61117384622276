import { useContext, useEffect, useRef } from "react";
import globalStates from "../../utils/global";
import "./AboutScreen1.css";
import { BannerAbout } from "../../assets";

const AboutScreen1 = ()=>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const screenAbout1Ref = useRef(null) as any;
    useEffect(()=>{
        const handleScroll =()=>{
            const setScreensOffset = globalContext && globalContext.setScreensOffset;
            const screenHome1 = screenAbout1Ref.current.getBoundingClientRect();
            const top = screenHome1.top;
            const bottom = screenHome1.bottom;
            const offset = globalContext && globalContext.windowHeight;
            

            setScreensOffset((prev:any)=>{return {...prev, 
                isOffsetScreenHome1: false, bottom, offset, top
            }});
            if(top < (-1*0.005*offset) ){
                setScreensOffset((prev:any)=>{return {...prev, bottom, offset, top,
                    isOffsetScreenHome1: true,
                }});
            };
        
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
        window.removeEventListener('scroll', handleScroll);
        };
    });
    
    return(
        <div ref={screenAbout1Ref}   className={`${windowWidthClass}-about-screen1`}>
            <div className="screen-img">
                <img alt="programmer bikinin" src={BannerAbout}/>
            </div>
            <div className="screen-title">
                <h2>Team Freelance Independent</h2>
            </div>
            <div className="screen-content">
                <p>Team professional menawarkan solusi teknologi dengan biaya yang terjangkau untuk UMKM.</p>
            </div>
            
        </div>
    )
};

export default AboutScreen1;