import { RequirementForm } from "../../containers";
import "./RequirementFormPage.css";

const RequirementFormPage = () =>{
    return(
        <div className="requirement-form-page">
            <RequirementForm/>
        </div>
    )
};

export default RequirementFormPage;