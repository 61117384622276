

import { Dispatch } from "redux"
import axios from 'axios';
import globalStates from "../../utils/global";

const BASE_URL = globalStates && globalStates.BASE_URL;
const actionTypes = {
    loading: 'REQUIREMENT_FORM_LOADING',
    success: 'REQUIREMENT_FORM_SUCCESS',
    error: 'REQUIREMENT_FORM_ERROR'
}

const postRequirementForm = async ({BASE_URL, requestBody, dispatch, cb}:any) =>{
     try {
        const response = await axios.post(BASE_URL, requestBody);
        cb();
        // console.log('Success:', response.data);
        return response
    } catch (error:any) {
        // console.log('Error form:', error);
        const errorResponse =( error && error.response) || {};
        const errorData = (errorResponse && errorResponse.data) || {};
        const errorDataMessage =( errorData && errorData.message)|| {};
        const errorDataMessageValue ="Pastikan semua data sudah terisi" ||  (errorDataMessage && errorDataMessage.message) || "error form unknown";
        // console.log('Error form message:', errorDataMessageValue)
        throw new Error(errorDataMessageValue)
        // const errorMessage = error && error.message 
        // dispatch({
        //     type: actionTypes.error,
        //     message: errorMessage,
        // })
    }
};

const requirementFormAction = ({data, method, cb}:any) => async (dispatch:Dispatch) =>{
    try {
        //loading
        dispatch({
            type: actionTypes.loading,
            loading: true
        });

        // action
        let response:any = {};
        if(method==="POST"){
            // console.log(">>>posting to", BASE_URL)
            const requestBody = data || {};
            response = await postRequirementForm({BASE_URL, requestBody, dispatch, cb});
        }
        const dispatchPayload = response; 

        //success
        dispatch({
            type: actionTypes.success,
            payload: dispatchPayload
        })
    } catch(error:any){
        //error 
        // console.log("error form action", error);
        const errorMessage = error && error.message 
        dispatch({
            type: actionTypes.error,
            message: errorMessage,
        })
    }
}

export default requirementFormAction;