import { useContext, useState } from "react";
import globalStates from "../../utils/global";
import "./HomeScreen3.css";
import { HomeMacbookImg2, IconClose, IconCompanyProfile, IconOnlinePayment, IconWebApplication } from "../../assets";

const HomeScreen3 = () =>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;

    const [isPreview, setIsPreview] = useState(false);

    const dataPreviews = [
        {
            name: "Company Profile",
            imgSrc: IconCompanyProfile,
            collection: [
                {
                    name: "PROPERTY",
                    imgSrc: HomeMacbookImg2
                },
                {
                    name: "SKINCARE",
                    imgSrc: HomeMacbookImg2
                },
                {
                    name: "TRAVEL",
                    imgSrc: HomeMacbookImg2
                }
            ]
        },
        {
            name: "Online Payment",
            imgSrc: IconOnlinePayment,
            collection: [
                {
                    name: "TRAVEL",
                    imgSrc: HomeMacbookImg2
                },
                {
                    name: "KLINIK",
                    imgSrc: HomeMacbookImg2
                },
                {
                    name: "KULINER",
                    imgSrc: HomeMacbookImg2
                }
            ]
        },
        {
            name: "Web Application",
            imgSrc: IconWebApplication,
            collection: [
                {
                    name: "BERITA",
                    imgSrc: HomeMacbookImg2
                },
                {
                    name: "LANGGANAN",
                    imgSrc: HomeMacbookImg2
                },
                {
                    name: "E-COMMERCE",
                    imgSrc: HomeMacbookImg2
                }
            ]
        }
    ]

    
    const [previewName, setPreviewName] = useState("");
    const handlePreview = (group:string) =>{
        setIsPreview(!isPreview);
        setPreviewName(group);
        // console.log(group)
    }
    const openLinkHandler = (link:any) =>{
        window.open(link, '_blank');
    }
    const handleButtonLayanan = (paketString:string) =>{
        const waMessage = `https://wa.me/6287715376976?text=Halo,%20saya%20mau%20request%20%20dibikinin%20website%20${paketString}.%20Mohon%20bantuan%20informasinya.%20Terima%20kasih.%20`
        openLinkHandler(waMessage);
    } 

    let filterPreview = dataPreviews.filter((preview:any)=>preview.name===previewName)
    // console.log(">>>previewName", previewName, {filterPreview})
    
    
    return(
        <>
        <div className={`${windowWidthClass}-home-screen3`}>
            <div className="wrapper">
                {
                    windowWidthClass==="w-mob"?
                    <>
                    {
                        dataPreviews.map((child1:any, index:any)=>{
                        const groupName = child1 && child1.name;
                        const groupImg = child1 && child1.imgSrc;
                        // const groupCollection = child1 && child1.collection;

                        return(
                            <>
                            <div key={index} className="grid-item" 
                            >
                                <img alt={groupName} src={groupImg} onClick={windowWidthClass==="w-mob"?()=>handlePreview(groupName) : ()=>null} />
                                <h2 onClick={()=>handlePreview(groupName)}>{groupName}</h2>
                            </div>
                            
                            </>
                        )
                    })
                    }
                    </> :
                    dataPreviews.map((child1:any, index:any)=>{
                        const groupName = child1 && child1.name;
                        const groupImg = child1 && child1.imgSrc;
                        const groupCollection = child1 && child1.collection;

                        return(
                            <div key={index} className="grid-item">
                                <img alt={groupName} src={groupImg} />
                                <h2>{groupName}</h2>
                                <div className="child1-grid">
                                    {
                                        groupCollection.map((child2:any, index:any)=>{
                                            const group2Name = child2 && child2.name;
                                            const group2Img = child2 && child2.imgSrc;

                                            return(
                                                <>
                                                <div key={index} className="grid-item">
                                                    <p>{group2Name}</p>
                                                    <img alt={group2Name} src={group2Img} 
                                                    onClick={()=>handleButtonLayanan(group2Name)}
                                                    />
                                                </div>
                                                
                                                </>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
             {
                                isPreview ?
                                filterPreview.map((child1:any, index:any)=>{
                                    const groupName = child1 && child1.name;
                                    const groupCollection = child1 && child1.collection;
                                    return (
                                    <div className={`w-mob-preview`}>
                                    
                                    <div className="child1-grid">
                                        <div className="first-row">
                                            <img alt={groupName} src={IconClose} onClick={()=>handlePreview(groupName)}/>
                                            <h2 >{groupName}</h2>
                                        </div>
                                        
                                        {
                                            groupCollection.map((child2:any, index:any)=>{
                                                const group2Name = child2 && child2.name;
                                                const group2Img = child2 && child2.imgSrc;

                                                return(
                                                    <>
                                                    <div key={index} className="grid-item">
                                                        <p>{group2Name}</p>
                                                        <img alt={group2Name} src={group2Img} 
                                                            onClick={()=>handleButtonLayanan(group2Name)}
                                                        />
                                                    </div>
                                                    
                                                    </>
                                                )
                                            })
                                        }
                                    </div>
                                </div>
                                    )
                                })
                            
 
                                : null
                            }   
        </>
    )
};

export default HomeScreen3;