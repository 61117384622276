import { useContext, useEffect, useState } from "react";
import globalStates from "../../utils/global";
import "./ErrorSnackBar.css";

const ErrorSnackBar = ({message}:any) =>{


    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    const errorMessage = message || "Sepertinya sedang banyak permintaaan, coba lagi dalam beberapa saat.";

    const [momentaryDisplay, setMomentaryDisplay] = useState(true);

    useEffect(()=>{
        // alert("timeout")
        setTimeout(()=>{
            setMomentaryDisplay(false)
        }, 5000)
    }, [])
    return(
        <div className={`${windowWidthClass}-error-snack-bar ${momentaryDisplay ? "": "hide"}`}>
            <p>{errorMessage}</p>
        </div>
    )
};

export default ErrorSnackBar;