import { useContext } from "react";
import { AppFooter, AppNavbar } from "../../components";
import { HargaScreen1, HargaScreen2, HargaScreen2Online, HargaScreen2Webapp, HargaScreen3 } from "../../containers";
import globalStates from "../../utils/global";
import "./Harga.css";

const Harga = ()=>{

     const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const appData = globalContext && globalContext.appData;
    const dataHarga = appData && appData.harga;
    const priceSection = (dataHarga && dataHarga.priceSection) || "";

    // console.log(">>>harga page", {appData})

    return (
        <div className="app-page harga">
            <AppNavbar/>
            <HargaScreen1/>
            {
                priceSection=== "paket-website"  ?
                 <HargaScreen2/> : null
            }
            {
                priceSection==="online-payment" ?
                <HargaScreen2Online/> : null
            }
            {
                priceSection==="web-app" ? 
                <HargaScreen2Webapp/> : null
            }
           
            <HargaScreen3/>
            <AppFooter/>
        </div>
    )
};

export default Harga;