import { useContext } from "react";
import globalStates from "../../../../utils/global";
import "./DemoTravelPaket.css";
import { AppNavbar } from "../../../../components";
import { DemoNavbar } from "../../component";
import { DemoTravelPaketScreen1 } from "../../container";

const DemoTravelPaket =()=>{

    const context = globalStates && globalStates.globalContext;
    const globalContext:any = useContext(context);
    const windowWidthClass = globalContext && globalContext.windowWidthClass;
    
    return (
        <div className={`${windowWidthClass}-demo-travel-paket`}>
            <DemoNavbar/>
            <DemoTravelPaketScreen1/>
        </div>
    )
};

export default DemoTravelPaket;
